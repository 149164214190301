<template>
  <div>
    <Loading v-if="loading" />
    <router-view />
    <Home />
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import Home from "@/views/Home.vue";
export default {
  name: "App",
  components: {
    Loading,
    Home,
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  watch: {
    loading() {
      setTimeout(() => {
        this.$store.commit("SET_LOADING", false);
      }, 1500);
    },
  },
};
</script>
