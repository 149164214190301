<template>
  <div>
    <TopoInterna :topoInterna="textoTopo" />
    <CoverInterna :coverInterna="cover1" :coverInternaMobile="coverMobile1" />
    <ImagemInterna :imagemInterna="imagem1" />
    <ImagemInterna :imagemInterna="imagem2" />
    <ImagemInterna :imagemInterna="imagem3" />
    <CoverInterna :coverInterna="cover2" :coverInternaMobile="coverMobile2" />
    <ImagemInterna :imagemInterna="imagem4" />
    <CoverInterna :coverInterna="cover3" :coverInternaMobile="coverMobile3" />
  </div>
</template>

<script>
import Img1 from "@/assets/img/07_elleve/2_img_elleve.png";
import Img2 from "@/assets/img/07_elleve/3_img_elleve.png";
import Img3 from "@/assets/img/07_elleve/4_img_elleve.jpg";
import Img4 from "@/assets/img/07_elleve/6_img_elleve.jpg";
import Cover1 from "@/assets/img/07_elleve/1_img_elleve_full.jpg";
import CoverMobile1 from "@/assets/img/07_elleve/1_img_elleve_mobile.jpg";
import Cover2 from "@/assets/img/07_elleve/5_img_elleve_full.jpg";
import CoverMobile2 from "@/assets/img/07_elleve/5_img_elleve_mobile.jpg";
import Cover3 from "@/assets/img/07_elleve/7_img_elleve_full.jpg";
import CoverMobile3 from "@/assets/img/07_elleve/7_img_elleve_mobile.jpg";
//
import TopoInterna from "@/components/TopoInterna.vue";
import ImagemInterna from "@/components/ImagemInterna.vue";
import CoverInterna from "@/components/CoverInterna.vue";
export default {
  name: "Elleve",
  components: {
    TopoInterna,
    ImagemInterna,
    CoverInterna,
  },
  data() {
    return {
      textoTopo: {
        titulo: "Elleve",
        texto: "freelance • visual identity",
      },
      cover1: Cover1,
      coverMobile1: CoverMobile1,
      cover2: Cover2,
      coverMobile2: CoverMobile2,
      cover3: Cover3,
      coverMobile3: CoverMobile3,
      imagem1: {
        imagem: Img1,
        alinhamento: "esquerda",
        topo: true,
      },
      imagem2: {
        imagem: Img2,
        alinhamento: "direita",
      },
      imagem3: {
        imagem: Img3,
        alinhamento: "esquerda",
      },
      imagem4: {
        imagem: Img4,
        alinhamento: "direita",
        topo: true,
      },
    };
  },
};
</script>
