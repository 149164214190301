<template>
  <div>
    <TopoInterna :topoInterna="textoTopo" />
    <VideoInterna :videoInterna="video1" />
    <ImagemInterna :imagemInterna="imagem1" />
    <VideoInterna :videoInterna="video2" />
    <ImagemInterna :imagemInterna="imagem2" />
  </div>
</template>

<script>
import Video1 from "@/assets/media/01_anbima_2018/1_video_anbima.mp4";
import Video2 from "@/assets/media/01_anbima_2018/3_video_anbima.mp4";
import Img1 from "@/assets/img/01_anbima_2018/2_img_anbima_2018.jpg";
import Img2 from "@/assets/img/01_anbima_2018/4_img_anbima_2018.jpg";

//
import TopoInterna from "@/components/TopoInterna.vue";
import VideoInterna from "@/components/VideoInterna.vue";
import ImagemInterna from "@/components/ImagemInterna.vue";
export default {
  name: "RelatorioAnbima2018",
  components: {
    TopoInterna,
    VideoInterna,
    ImagemInterna,
  },
  data() {
    return {
      textoTopo: {
        titulo: "Relatório Anual<br>Anbima 2018",
        texto: "freelance • website design",
      },
      video1: Video1,
      video2: Video2,
      imagem1: {
        imagem: Img1,
        alinhamento: "direita",
        topo: true,
      },
      imagem2: {
        imagem: Img2,
        alinhamento: "esquerda",
        topo: true,
      },
    };
  },
};
</script>
