<template>
  <section class="interna-cover" :class="{ menor: imagemMenor }">
    <picture>
      <source media="(max-width: 600px)" :srcset="coverInternaMobile" />
      <img :src="coverInterna" alt="Ivan Romain" />
    </picture>
  </section>
</template>

<script>
export default {
  name: "CoverInterna",
  props: ["coverInterna", "coverInternaMobile", "imagemMenor"],
};
</script>

<style lang="scss">
.interna-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &.menor {
    min-height: 100vh;
    background: $preto;
    @media (max-width: 600px) {
      min-height: auto;
      background: none;
    }
  }
  picture {
    line-height: 0;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
</style>
