<template>
  <div class="card-home">
    <div class="card-home-imagem" :data-card-home="`card-home-imagem-${cardInfo.id}`" :data-deg="gerarNumeroDeg()">
      <img :src="cardInfo.img" alt="Ivan Romain" />
    </div>
    <router-link custom :to="cardInfo.link" class="card-home-link" v-slot="{ navigate }">
      <div @click="navigate" @keypress.enter="navigate" role="link">
        <h3 class="card-home-title">{{ cardInfo.titulo }}</h3>
        <p class="card-home-description">{{ cardInfo.texto }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CardHome",
  props: ["cardInfo"],
  methods: {
    gerarNumeroDeg() {
      const umDois = Math.floor(Math.random() * (2 - 1 + 1) + 1);
      const valor = (Math.random() * (0.1 - 0.05) + 0.05).toFixed(4);
      return umDois == 1 ? valor : -valor;
    },
  },
  mounted() {
    const item = document.querySelector(`[data-card-home="card-home-imagem-${this.cardInfo.id}"]`);
    let valor = (item.offsetTop + item.scrollHeight - window.pageYOffset) * -item.dataset.deg;
    if (item.style) {
      item.style.transform = `rotate3d(0,0,1,${valor / 1.5}deg)`;
      item.querySelector("img").style.transform = `translate3d(0px,${valor}px,0px)`;
    }
  },
};
</script>

<style lang="scss">
.card-home {
  margin-bottom: 394px;
  .card-home-link {
    color: $branco;
    cursor: pointer;
    position: relative;
    margin-top: 10px;
    .card-home-title {
      text-transform: uppercase;
      line-height: 20px;
    }
    .card-home-title,
    .card-home-description {
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: 0.3s;
      -webkit-text-decoration-color: transparent;
      -moz-text-decoration-color: transparent;
    }
    &:hover {
      .card-home-title,
      .card-home-description {
        text-decoration-color: $branco;
        -webkit-text-decoration-color: $branco;
        -moz-text-decoration-color: $branco;
      }
    }
  }
  .card-home-imagem {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
      content: "";
      padding-bottom: 56%;
      display: block;
    }
    img {
      position: absolute;
      width: 130%;
      height: 130%;
    }
  }
}
</style>
