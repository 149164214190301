<template>
  <div>
    <TopoInterna :topoInterna="textoTopo" />
    <VideoInterna :videoInterna="video1" />
    <ImagemInterna :imagemInterna="imagem1" />
    <VideoInterna :videoInterna="video2" />
    <ImagemInterna :imagemInterna="imagem2" />
    <ImagemInterna :imagemInterna="imagem3" />
    <ImagemInterna :imagemInterna="imagem4" />
  </div>
</template>

<script>
import Video1 from "@/assets/media/02_anbima_2019/01_anbima19_video.mp4";
import Video2 from "@/assets/media/02_anbima_2019/03_anbima19.mp4";
import Img1 from "@/assets/img/02_anbima_2019/2_img_anbima_2019.jpg";
import Img2 from "@/assets/img/02_anbima_2019/4_img_anbima_2019.jpg";
import Img3 from "@/assets/img/02_anbima_2019/5_img_anbima_2019.jpg";
import Img4 from "@/assets/img/02_anbima_2019/6_img_anbima_2019.jpg";
//
import TopoInterna from "@/components/TopoInterna.vue";
import VideoInterna from "@/components/VideoInterna.vue";
import ImagemInterna from "@/components/ImagemInterna.vue";
export default {
  name: "RelatorioAnbima2019",
  components: {
    TopoInterna,
    VideoInterna,
    ImagemInterna,
  },
  data() {
    return {
      textoTopo: {
        titulo: "Relatório Anual<br>Anbima 2019",
        texto: "freelance • website design",
      },
      video1: Video1,
      video2: Video2,
      imagem1: {
        imagem: Img1,
        alinhamento: "direita",
        topo: true,
      },
      imagem2: {
        imagem: Img2,
        alinhamento: "esquerda",
        topo: true,
      },
      imagem3: {
        imagem: Img3,
        alinhamento: "direita",
      },
      imagem4: {
        imagem: Img4,
        alinhamento: "direita",
      },
    };
  },
};
</script>
