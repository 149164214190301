<template>
  <section class="topo-interna">
    <!-- <router-link custom to="/" class="topo-interna-logo" v-slot="{ navigate }">
      <div @click="navigate" @keypress.enter="navigate" role="link">
        <img id="topo-interna-logo-img" src="@/assets/img/logos/logo_smile_preto_interna.svg" alt="Ivan Romain" />
      </div>
    </router-link> -->
    <div class="topo-interna-logo">
      <img id="topo-interna-logo-img" src="@/assets/img/logos/logo_smile_preto_interna.svg" alt="Ivan Romain" />
    </div>
    <div class="topo-interna-texto">
      <h2 v-html="topoInterna.titulo"></h2>
      <h3>{{ topoInterna.texto }}</h3>
    </div>
  </section>
</template>

<script>
export default {
  name: "TopoInterna",
  props: ["topoInterna"],
  methods: {
    scrollRotateLogo() {
      const image = document.getElementById("topo-interna-logo-img");
      if (image) {
        return (image.style.transform = "rotate(" + window.pageYOffset / 5 + "deg)");
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.scrollRotateLogo();
    });
  },
};
</script>

<style lang="scss">
.topo-interna {
  height: 100vh;
  padding: 35px 20px;
  width: 100%;
  position: sticky;
  top: 0;
  .topo-interna-logo {
    height: 35px;
    width: 35px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    // cursor: pointer;
  }
  .topo-interna-texto {
    height: 100%;
    display: flex;
    padding-bottom: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      font-size: 50px;
      line-height: 50px;
      text-align: center;
      text-transform: uppercase;
    }
    h3 {
      margin-top: 15px;
      text-align: center;
      line-height: 20px;
    }
  }
}
</style>
