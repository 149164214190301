<template>
  <div>
    <TopoInterna :topoInterna="textoTopo" />
    <CoverInterna :coverInterna="cover1" :coverInternaMobile="coverMobile1" />
    <ImagemInterna :imagemInterna="imagem1" />
    <ImagemInterna :imagemInterna="imagem2" />
    <CoverInterna :coverInterna="cover2" :coverInternaMobile="coverMobile2" />
    <ImagemInterna :imagemInterna="imagem3" />
    <CoverInterna :coverInterna="cover3" :coverInternaMobile="coverMobile3" />
    <ImagemInterna :imagemInterna="imagem4" />
    <CoverInterna :coverInterna="cover4" :coverInternaMobile="coverMobile4" />
  </div>
</template>

<script>
import Img1 from "@/assets/img/10_poster/2_poster.png";
import Img2 from "@/assets/img/10_poster/3_poster_full.png";
import Img3 from "@/assets/img/10_poster/5_poster_full.png";
import Img4 from "@/assets/img/10_poster/7_poster_full.png";
import Cover1 from "@/assets/img/10_poster/1_poster_full.jpg";
import CoverMobile1 from "@/assets/img/10_poster/1_poster_mobile.jpg";
import Cover2 from "@/assets/img/10_poster/4_poster_full.jpg";
import CoverMobile2 from "@/assets/img/10_poster/4_poster_mobile.jpg";
import Cover3 from "@/assets/img/10_poster/6_poster_full.jpg";
import CoverMobile3 from "@/assets/img/10_poster/6_poster_mobile.jpg";
import Cover4 from "@/assets/img/10_poster/8_poster_full.jpg";
import CoverMobile4 from "@/assets/img/10_poster/8_poster_mobile.jpg";
//
import TopoInterna from "@/components/TopoInterna.vue";
import ImagemInterna from "@/components/ImagemInterna.vue";
import CoverInterna from "@/components/CoverInterna.vue";
export default {
  name: "PosterCompilation",
  components: {
    TopoInterna,
    ImagemInterna,
    CoverInterna,
  },
  data() {
    return {
      textoTopo: {
        titulo: "Poster<br>Compilation",
        texto: "personal • art direction",
      },
      cover1: Cover1,
      coverMobile1: CoverMobile1,
      cover2: Cover2,
      coverMobile2: CoverMobile2,
      cover3: Cover3,
      coverMobile3: CoverMobile3,
      cover4: Cover4,
      coverMobile4: CoverMobile4,
      imagem1: {
        imagem: Img1,
        alinhamento: "direita",
        topo: true,
      },
      imagem2: {
        imagem: Img2,
        alinhamento: "esquerda",
      },
      imagem3: {
        imagem: Img3,
        alinhamento: "esquerda",
        topo: true,
      },
      imagem4: {
        imagem: Img4,
        alinhamento: "centro",
        topo: true,
      },
    };
  },
};
</script>
