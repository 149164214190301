<template>
  <div>
    <TopoInterna :topoInterna="textoTopo" />
    <CoverInterna :coverInterna="cover1" :coverInternaMobile="coverMobile1" />
    <ImagemInterna :imagemInterna="imagem1" />
    <ImagemInterna :imagemInterna="imagem2" />
    <CoverInterna :coverInterna="cover2" :coverInternaMobile="coverMobile2" />
    <ImagemInterna :imagemInterna="imagem3" />
    <ImagemInterna :imagemInterna="imagem4" />
    <ImagemInterna :imagemInterna="imagem5" />
  </div>
</template>

<script>
import Img1 from "@/assets/img/06_corinthians/2_img_corinthians.jpg";
import Img2 from "@/assets/img/06_corinthians/3_img_corinthians.jpg";
import Img3 from "@/assets/img/06_corinthians/5_img_corinthians.jpg";
import Img4 from "@/assets/img/06_corinthians/6_img_corinthians.jpg";
import Img5 from "@/assets/img/06_corinthians/7_img_corinthians_full.jpg";
import Cover1 from "@/assets/img/06_corinthians/1_img_corinthians_full.jpg";
import CoverMobile1 from "@/assets/img/06_corinthians/1_img_corinthians_mobile.jpg";
import Cover2 from "@/assets/img/06_corinthians/4_img_corinthians_full.jpg";
import CoverMobile2 from "@/assets/img/06_corinthians/4_img_corinthians_mobile.jpg";
//
import TopoInterna from "@/components/TopoInterna.vue";
import ImagemInterna from "@/components/ImagemInterna.vue";
import CoverInterna from "@/components/CoverInterna.vue";
export default {
  name: "Corinthians",
  components: {
    TopoInterna,
    ImagemInterna,
    CoverInterna,
  },
  data() {
    return {
      textoTopo: {
        titulo: "Corinthians",
        texto: "concept • art direction",
      },
      cover1: Cover1,
      coverMobile1: CoverMobile1,
      cover2: Cover2,
      coverMobile2: CoverMobile2,
      imagem1: {
        imagem: Img1,
        alinhamento: "direita",
        topo: true,
      },
      imagem2: {
        imagem: Img2,
        alinhamento: "esquerda",
      },
      imagem3: {
        imagem: Img3,
        alinhamento: "direita",
        topo: true,
      },
      imagem4: {
        imagem: Img4,
        alinhamento: "esquerda",
      },
      imagem5: {
        imagem: Img5,
        alinhamento: "esquerda",
      },
    };
  },
};
</script>
