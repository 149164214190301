<template>
  <section class="interna-video">
    <div>
      <video loop="loop" playsinline="" autoplay="autoplay" muted="muted" :src="videoInterna" type="video/mp4"></video>
    </div>
  </section>
</template>

<script>
export default {
  name: "VideoInterna",
  props: ["videoInterna"],
};
</script>

<style lang="scss">
.interna-video {
  min-height: 100vh;
  background: $preto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 100%;
    max-width: 855px;
    position: relative;
    &:after {
      content: "";
      padding-bottom: 56.2%;
      display: block;
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
