<template>
  <section class="imagem-interna" :class="[{ topo: imagemInterna.topo }, imagemInterna.alinhamento]">
    <img :src="imagemInterna.imagem" alt="Ivan Romain" />
  </section>
</template>

<script>
export default {
  name: "ImagemInterna",
  props: ["imagemInterna"],
};
</script>

<style lang="scss">
.imagem-interna {
  width: 100%;
  padding: 0 50px 394px;
  display: flex;
  position: relative;
  &.topo {
    padding-top: 394px;
  }
  &.esqueda {
    justify-content: flex-start;
  }
  &.direita {
    justify-content: flex-end;
  }
  &.centro {
    justify-content: center;
  }
  img {
    max-width: 100%;
  }
}
</style>
