<template>
  <footer class="footer">
    <p>Hello! I’m Ivan Romain, art director and Brazilian designer with over nine years of experience working with digital, graphic, and visual communication design. My goal is to deliver creative and efficient solutions through media and digital platforms. If you are interested in further information or know more about my work, do not hesitate to contact me.</p>
    <div class="footer-links">
      <a href="https://dribbble.com/romainivan" target="_blank" class="link dribble" rel="noreferer noopener">Dribbble</a>
      <a href="https://www.behance.net/romainivanb2b4" target="_blank" class="link behance" rel="noreferer noopener">Behance</a>
      <a href="https://www.linkedin.com/in/romainivan/" target="_blank" class="link linkedin" rel="noreferer noopener">LinkedIn</a>
      <a href="mailto:romainivan@gmail.com" target="_blank" class="link email" rel="noreferer noopener">romainivan@gmail.com</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
.footer {
  position: relative;
  padding-bottom: 30px;
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  p {
    color: $branco;
    text-align: center;
    line-height: 20px;
    margin-bottom: 53px;
    @media (max-width: 600px) {
      margin-bottom: 30px;
    }
  }
  .footer-links {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      a {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
