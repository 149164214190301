<template>
  <div id="home">
    <section class="home-title">
      <div class="home-container">
        <transition name="logo" appear>
          <h1 class="logo-home" v-if="logoCarregado">
            <img class="logo-home-texto" src="@/assets/img/logos/logo_separado.svg" alt="Ivan Romain" />
            <img v-if="cardCarregado" class="logo-home-smile" id="rotate-home" src="@/assets/img/logos/logo_smile.svg" alt="Ivan Romain" />
            <img v-else class="logo-home-smile-fixo" id="rotate-home-fixo" src="@/assets/img/logos/logo_smile.svg" alt="Ivan Romain" />
          </h1>
        </transition>
        <transition name="logo" appear>
          <h2 class="texto-home" v-if="textoCarregado">art director and designer</h2>
        </transition>
      </div>
    </section>
    <transition-group v-if="cardCarregado" name="fade" appear>
      <section class="home-cards" :key="1">
        <CardHome v-for="item in cards" :key="item.id" :cardInfo="item" />
      </section>
      <Footer :key="2" />
    </transition-group>
  </div>
</template>

<script>
import Img1 from "@/assets/img/home/1_img_home.jpg";
import Img2 from "@/assets/img/home/2_img_home.jpg";
import Img3 from "@/assets/img/home/3_img_home.jpg";
import Img4 from "@/assets/img/home/4_img_home.jpg";
import Img5 from "@/assets/img/home/5_img_home.jpg";
import Img6 from "@/assets/img/home/6_img_home.jpg";
import Img7 from "@/assets/img/home/7_img_home.jpg";
import Img8 from "@/assets/img/home/8_img_home.jpg";
import Img9 from "@/assets/img/home/9_img_home.jpg";
import Img10 from "@/assets/img/home/10_img_home.jpg";
//
import CardHome from "@/components/CardHome.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Home",
  components: {
    CardHome,
    Footer,
  },
  data() {
    return {
      logoCarregado: false,
      textoCarregado: false,
      cardCarregado: false,
      cards: [
        {
          id: 0,
          img: Img1,
          titulo: "Relatório Anual Anbima 2018",
          texto: "freelance • website design",
          link: "/relatorio-anual-anbima-2018",
        },
        {
          id: 1,
          img: Img2,
          titulo: "Relatório Anual Anbima 2019",
          texto: "freelance • website design",
          link: "/relatorio-anual-anbima-2019",
        },
        {
          id: 2,
          img: Img3,
          titulo: "Barzel Properties",
          texto: "freelance • website design",
          link: "/barzel-properties",
        },
        {
          id: 3,
          img: Img4,
          titulo: "Brasileirão",
          texto: "concept • visual identity • art direction",
          link: "/brasileirao",
        },
        {
          id: 4,
          img: Img5,
          titulo: "Caturama",
          texto: "personal • visual identity • art direction",
          link: "/caturama",
        },
        {
          id: 5,
          img: Img6,
          titulo: "Corinthians",
          texto: "concept • art direction",
          link: "/corinthians",
        },
        {
          id: 6,
          img: Img7,
          titulo: "Elleve",
          texto: "freelance • visual identity",
          link: "/elleve",
        },
        {
          id: 7,
          img: Img8,
          titulo: "Embrasa",
          texto: "freelance • website design • visual identity",
          link: "/embrasa",
        },
        {
          id: 8,
          img: Img9,
          titulo: "Páginas Coloridas Coral",
          texto: "freelance • product • ui • ux • art direction",
          link: "/paginas-coloridas-coral",
        },
        {
          id: 9,
          img: Img10,
          titulo: "Poster Compilation",
          texto: "personal • art direction",
          link: "/poster-compilation",
        },
      ],
    };
  },
  methods: {
    verificaHome() {
      const item = document.querySelector("#home");
      if (window.innerWidth > 600) {
        if (window.pageYOffset >= item.offsetTop && this.$route.name != "home") {
          this.$router.push("/");
        }
      } else {
        if (window.pageYOffset + 70 >= item.offsetTop && this.$route.name != "home") {
          this.$router.push("/");
        }
      }
    },
    scrollRotateLogo() {
      let image = document.getElementById("rotate-home");
      if (image) {
        return (image.style.transform = "rotate(" + window.pageYOffset / 5 + "deg)");
      }
    },
    scrollRotateImages() {
      const elementos = document.querySelectorAll("[data-card-home]");
      elementos.forEach((item) => {
        let valor = (item.offsetTop + item.scrollHeight - window.pageYOffset) * -item.dataset.deg;
        if (item.style) {
          item.style.transform = `rotate3d(0,0,1,${valor / 1.5}deg)`;
          item.querySelector("img").style.transform = `translate3d(0px,${valor}px,0px)`;
        }
      });
    },
  },
  mounted() {
    if (this.$route.name == "home") {
      setTimeout(() => {
        this.logoCarregado = true;
      }, 300);
      setTimeout(() => {
        this.textoCarregado = true;
      }, 1300);
      setTimeout(() => {
        this.cardCarregado = true;
      }, 2300);
    } else {
      this.logoCarregado = true;
      this.textoCarregado = true;
    }
    window.addEventListener("scroll", () => {
      this.scrollRotateLogo();
      this.scrollRotateImages();
      this.verificaHome();
    });
  },
  watch: {
    $route(to) {
      if (to.name != "home") {
        this.logoCarregado = true;
        this.textoCarregado = true;
        this.cardCarregado = false;
        setTimeout(() => {
          let image = document.getElementById("rotate-home-fixo");
          if (image) {
            image.style.transform = "rotate(0deg)";
          }
        }, 100);
      } else {
        this.logoCarregado = true;
        this.textoCarregado = true;
        this.cardCarregado = true;
      }
    },
  },
};
</script>

<style lang="scss">
#home {
  background: $preto;
  padding: 0 10vw;
  box-sizing: border-box;
  min-height: 100vh;
  .home-title {
    min-height: 300px;
    padding-bottom: 40px;
    height: 100vh;
    position: sticky;
    top: 0;
    color: $branco;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (max-width: 480px) {
      padding-top: 0px;
      padding-bottom: 70px;
      height: calc(100vh - 150px);
    }
    @media (max-width: 374px) {
      height: 50vh;
      padding-top: 100px;
      justify-content: flex-start;
    }
  }
  .home-container {
    height: 80px;
  }
  .logo-home {
    position: relative;
    width: 310px;
    height: 35px;
    margin-bottom: 25px;
    &-texto {
      width: 310px;
      height: 35px;
    }
    &-smile,
    &-smile-fixo {
      width: 35px;
      height: 35px;
      position: absolute;
      left: 151px;
    }
  }
  .texto-home {
    position: relative;
    text-align: center;
  }
  .home-cards {
    width: 100%;
    max-width: 750px;
    margin: -100px auto 0 auto;
    position: relative;
  }
}
</style>
