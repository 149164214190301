<template>
  <div>
    <TopoInterna :topoInterna="textoTopo" />
    <VideoInterna :videoInterna="video1" />
    <ImagemInterna :imagemInterna="imagem1" />
    <CoverInterna :coverInterna="cover" :coverInternaMobile="coverMobile" />
    <ImagemInterna :imagemInterna="imagem2" />
  </div>
</template>

<script>
import Img1 from "@/assets/img/03_barzel/2_img_barzel.jpg";
import Img2 from "@/assets/img/03_barzel/4_img_barzel.jpg";
import Video1 from "@/assets/media/03_barzel/1_video_barzel.mp4";
import Cover from "@/assets/img/03_barzel/3_img_barzel_full.jpg";
import CoverMobile from "@/assets/img/03_barzel/3_img_barzel_mobile.jpg";
//
import TopoInterna from "@/components/TopoInterna.vue";
import VideoInterna from "@/components/VideoInterna.vue";
import ImagemInterna from "@/components/ImagemInterna.vue";
import CoverInterna from "@/components/CoverInterna.vue";
export default {
  name: "BarzelProperties",
  components: {
    TopoInterna,
    VideoInterna,
    ImagemInterna,
    CoverInterna,
  },
  data() {
    return {
      textoTopo: {
        titulo: "Barzel<br>Properties",
        texto: "freelance • website design",
      },
      video1: Video1,
      cover: Cover,
      coverMobile: CoverMobile,
      imagem1: {
        imagem: Img1,
        alinhamento: "direita",
        topo: true,
      },
      imagem2: {
        imagem: Img2,
        alinhamento: "esquerda",
        topo: true,
      },
    };
  },
};
</script>
