<template>
  <div>
    <TopoInterna :topoInterna="textoTopo" />
    <CoverInterna :coverInterna="cover1" :coverInternaMobile="coverMobile1" />
    <ImagemInterna :imagemInterna="imagem1" />
    <ImagemInterna :imagemInterna="imagem2" />
    <ImagemInterna :imagemInterna="imagem3" />
    <ImagemInterna :imagemInterna="imagem4" />
    <CoverInterna :coverInterna="cover2" :coverInternaMobile="coverMobile2" :imagemMenor="true" />
    <ImagemInterna :imagemInterna="imagem5" />
    <ImagemInterna :imagemInterna="imagem6" />
    <ImagemInterna :imagemInterna="imagem7" />
    <ImagemInterna :imagemInterna="imagem8" />
    <ImagemInterna :imagemInterna="imagem9" />
  </div>
</template>

<script>
import Cover1 from "@/assets/img/04_brasileirao/0_img_brasileirao.jpg";
import CoverMobile1 from "@/assets/img/04_brasileirao/0_img_brasileirao-mobile.jpg";
import Cover2 from "@/assets/img/04_brasileirao/5_img_brasileirao.jpg";
import CoverMobile2 from "@/assets/img/04_brasileirao/5_img_brasileirao-mobile.jpg";
import Img1 from "@/assets/img/04_brasileirao/1_img_brasileirao.png";
import Img2 from "@/assets/img/04_brasileirao/2_gif_brasileirao.gif";
import Img3 from "@/assets/img/04_brasileirao/3_img_brasileirao.png";
import Img4 from "@/assets/img/04_brasileirao/4_img_brasileirao.png";
import Img5 from "@/assets/img/04_brasileirao/6_img_brasileirao.png";
import Img6 from "@/assets/img/04_brasileirao/7_img_brasileirao.jpg";
import Img7 from "@/assets/img/04_brasileirao/8_img_brasileirao.jpg";
import Img8 from "@/assets/img/04_brasileirao/9_img_brasileirao.jpg";
import Img9 from "@/assets/img/04_brasileirao/10_img_brasileirao.jpg";
//
import TopoInterna from "@/components/TopoInterna.vue";
import ImagemInterna from "@/components/ImagemInterna.vue";
import CoverInterna from "@/components/CoverInterna.vue";
export default {
  name: "Brasileirao",
  components: {
    TopoInterna,
    ImagemInterna,
    CoverInterna,
  },
  data() {
    return {
      textoTopo: {
        titulo: "Brasileirão",
        texto: "concept • visual identity • art direction",
      },
      cover1: Cover1,
      coverMobile1: CoverMobile1,
      cover2: Cover2,
      coverMobile2: CoverMobile2,
      imagem1: {
        imagem: Img1,
        alinhamento: "esquerda",
        topo: true,
      },
      imagem2: {
        imagem: Img2,
        alinhamento: "direita",
      },
      imagem3: {
        imagem: Img3,
        alinhamento: "esquerda",
      },
      imagem4: {
        imagem: Img4,
        alinhamento: "direita",
      },
      imagem5: {
        imagem: Img5,
        alinhamento: "esquerda",
        topo: true,
      },
      imagem6: {
        imagem: Img6,
        alinhamento: "esquerda",
      },
      imagem7: {
        imagem: Img7,
        alinhamento: "direita",
      },
      imagem8: {
        imagem: Img8,
        alinhamento: "direita",
      },
      imagem9: {
        imagem: Img9,
        alinhamento: "direita",
      },
    };
  },
};
</script>
