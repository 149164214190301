<template>
  <div id="loading"></div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style lang="scss">
#loading {
  position: fixed;
  z-index: 100;
  left: 0;
  width: 100%;
  animation: loading 1.5s ease;
  background: #111;
}
@keyframes loading {
  0% {
    top: 0;
    bottom: auto;
    height: 0%;
  }
  20% {
    top: 0;
    bottom: auto;
    height: 100%;
  }
  80% {
    top: auto;
    bottom: 0;
    height: 100%;
  }
  100% {
    top: auto;
    bottom: 0;
    height: 0%;
  }
}
</style>
