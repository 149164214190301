import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
//
import HomeVazia from "@/components/HomeVazia.vue";
import RelatorioAnbima2018 from "@/views/RelatorioAnbima2018.vue";
import RelatorioAnbima2019 from "@/views/RelatorioAnbima2019.vue";
import BarzelProperties from "@/views/BarzelProperties.vue";
import Brasileirao from "@/views/Brasileirao.vue";
import Caturama from "@/views/Caturama.vue";
import Corinthians from "@/views/Corinthians.vue";
import Elleve from "@/views/Elleve.vue";
import Embrasa from "@/views/Embrasa.vue";
import PaginasColoridas from "@/views/PaginasColoridas.vue";
import PosterCompilation from "@/views/PosterCompilation.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "home",
    path: "/",
    component: HomeVazia,
  },
  {
    name: "relatorio-anual-anbima-2018",
    path: "/relatorio-anual-anbima-2018",
    component: RelatorioAnbima2018,
  },
  {
    name: "relatorio-anual-anbima-2019",
    path: "/relatorio-anual-anbima-2019",
    component: RelatorioAnbima2019,
  },
  {
    name: "barzel-properties",
    path: "/barzel-properties",
    component: BarzelProperties,
  },
  {
    name: "brasileirao",
    path: "/brasileirao",
    component: Brasileirao,
  },
  {
    name: "caturama",
    path: "/caturama",
    component: Caturama,
  },
  {
    name: "corinthians",
    path: "/corinthians",
    component: Corinthians,
  },
  {
    name: "elleve",
    path: "/elleve",
    component: Elleve,
  },
  {
    name: "embrasa",
    path: "/embrasa",
    component: Embrasa,
  },
  {
    name: "paginas-coloridas-coral",
    path: "/paginas-coloridas-coral",
    component: PaginasColoridas,
  },
  {
    name: "poster-compilation",
    path: "/poster-compilation",
    component: PosterCompilation,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name != "home" && from.name != null) {
    store.commit("SET_LOADING", true);
    setTimeout(() => {
      next();
    }, 750);
  } else {
    next();
  }
});

export default router;
