<template>
  <div>
    <TopoInterna :topoInterna="textoTopo" />
    <VideoInterna :videoInterna="video1" />
    <ImagemInterna :imagemInterna="imagem1" />
    <VideoInterna :videoInterna="video2" />
    <ImagemInterna :imagemInterna="imagem2" />
    <VideoInterna :videoInterna="video3" />
  </div>
</template>

<script>
import Img1 from "@/assets/img/09_paginas_coloridas/2_img_paginas_coloridas.jpg";
import Img2 from "@/assets/img/09_paginas_coloridas/4_img_paginas_coloridas.jpg";
import Video1 from "@/assets/media/09_paginas_coloridas/1_video_paginas_coloridas.mp4";
import Video2 from "@/assets/media/09_paginas_coloridas/3_video_paginas_coloridas.mp4";
import Video3 from "@/assets/media/09_paginas_coloridas/5_video_paginas_coloridas.mp4";
//
import TopoInterna from "@/components/TopoInterna.vue";
import VideoInterna from "@/components/VideoInterna.vue";
import ImagemInterna from "@/components/ImagemInterna.vue";
export default {
  name: "PaginasColoridas",
  components: {
    TopoInterna,
    VideoInterna,
    ImagemInterna,
  },
  data() {
    return {
      textoTopo: {
        titulo: "Páginas<br>Coloridas Coral",
        texto: "freelance • product • ui • ux • art direction",
      },
      video1: Video1,
      video2: Video2,
      video3: Video3,
      imagem1: {
        imagem: Img1,
        alinhamento: "direita",
        topo: true,
      },
      imagem2: {
        imagem: Img2,
        alinhamento: "esquerda",
        topo: true,
      },
    };
  },
};
</script>
