<template>
  <div>
    <TopoInterna :topoInterna="textoTopo" />
    <CoverInterna :coverInterna="cover1" :coverInternaMobile="coverMobile1" />
    <ImagemInterna :imagemInterna="imagem1" />
    <ImagemInterna :imagemInterna="imagem2" />
    <VideoInterna :videoInterna="video1" />
    <ImagemInterna :imagemInterna="imagem3" />
    <CoverInterna :coverInterna="cover2" :coverInternaMobile="coverMobile2" />
    <ImagemInterna :imagemInterna="imagem4" />
    <ImagemInterna :imagemInterna="imagem5" />
    <CoverInterna :coverInterna="cover3" :coverInternaMobile="coverMobile3" />
  </div>
</template>

<script>
import Img1 from "@/assets/img/08_embrasa/2_img_embrasa.png";
import Img2 from "@/assets/img/08_embrasa/3_img_embrasa.png";
import Img3 from "@/assets/img/08_embrasa/5_img_embrasa.jpg";
import Img4 from "@/assets/img/08_embrasa/7_img_embrasa.jpg";
import Img5 from "@/assets/img/08_embrasa/8_img_embrasa.jpg";
import Cover1 from "@/assets/img/08_embrasa/1_img_embrasa_full.jpg";
import CoverMobile1 from "@/assets/img/08_embrasa/1_img_embrasa_mobile.jpg";
import Cover2 from "@/assets/img/08_embrasa/6_gif_embrasa_full.gif";
import CoverMobile2 from "@/assets/img/08_embrasa/6_gif_embrasa_mobile.gif";
import Cover3 from "@/assets/img/08_embrasa/9_img_embrasa_full.jpg";
import CoverMobile3 from "@/assets/img/08_embrasa/9_img_embrasa_mobile.jpg";
import Video1 from "@/assets/media/08_embrasa/4_video_embrasa_full.mp4";
//
import TopoInterna from "@/components/TopoInterna.vue";
import ImagemInterna from "@/components/ImagemInterna.vue";
import VideoInterna from "@/components/VideoInterna.vue";
import CoverInterna from "@/components/CoverInterna.vue";
export default {
  name: "Embrasa",
  components: {
    TopoInterna,
    ImagemInterna,
    VideoInterna,
    CoverInterna,
  },
  data() {
    return {
      textoTopo: {
        titulo: "Embrasa",
        texto: "freelance • website design • visual identity",
      },
      video1: Video1,
      cover1: Cover1,
      coverMobile1: CoverMobile1,
      cover2: Cover2,
      coverMobile2: CoverMobile2,
      cover3: Cover3,
      coverMobile3: CoverMobile3,
      imagem1: {
        imagem: Img1,
        alinhamento: "direita",
        topo: true,
      },
      imagem2: {
        imagem: Img2,
        alinhamento: "esquerda",
      },
      imagem3: {
        imagem: Img3,
        alinhamento: "esquerda",
        topo: true,
      },
      imagem4: {
        imagem: Img4,
        alinhamento: "direita",
        topo: true,
      },
      imagem5: {
        imagem: Img5,
        alinhamento: "esquerda",
      },
    };
  },
};
</script>
